<template lang="pug">
//- 匯入CSV
v-card.v-card-scroll(height=700)
  //- v-overlay(v-if='importing' :z-index='99' opacity='0')    
  v-card-title.d-flex.justify-center {{$t('UPCHAT.TITLE')}}
  v-card-text.v-card-scroll__text(height='400')
    v-spacer.mt-10(v-for='item,index in chatMessage' :key='index')
      //- 客服
      v-row.chat_icon_div.d-flex.justify-end(color="rgba(0,0,0,0)" flat class="mb-12" v-if='item.message_type == 1 && item.sender')
        div.chat_icon_div(v-if='item.content')
          v-btn.chat_icon_bottom(width='0' height='32' color='green' plain border-0 @click='onCopyMsg(item.content)') {{$t('GENERAL.COPY')}}
        v-card(class="mr-9" max-width="350px" color="#1f93ff" dark )
          v-list-item(three-line)
            v-list-item-content
              div.upchat-message-div.d-flex.justify-end(class="mb-4") {{item.content}}
              div(v-if='item.attachments' )
                v-avatar(size="180" tile class="mr-2" v-for='img,index in item.attachments' :key='"img"+index' v-if='img.file_type == "image"' @click='onImage(img.data_url)')
                  img(height='180' :src="img.data_url" :href='img.data_url')
                //- v-avatar(size="60" tile class="mr-2" v-for='img in item.attachments' )
                //-   img(v-if='img.file_type == "image"' height='180' :scr="img.data_url" )
                div(v-for='file,index in item.attachments' :key='"file"+index' v-if='file.file_type == "file"')
                  v-row.mb-2
                    v-col(cols='1')
                      v-icon(size='40' :scr='file.data_url' ) fa-file 
                    v-col(cols='10')
                      span {{ (decodeURI(file.data_url)).split('/')[(file.data_url.split('/')).length - 1] }}
                      br
                      v-btn.d-flex.justify-start.ml-2(:ripple="false",:href="file.data_url",width="10",height="20",depressed color='rgba(0,0,0,0)').underline {{$t('IMPORT_EXPORT.DOWNLOAD')}}
              v-list-item-subtitle.d-flex.justify-end {{item.msg_time}} 
        v-avatar.chat_icon_bottom(class="" size="30" elevation="10")
          v-icon.mx-auto.chat_icon_backgroung_color(:title=`$t('UPCHAT.SENDER') + item.sender.name` background-color='rgba(100,100,100,100)') {{ (item.sender.name).slice(0,1) }}
      
      //- 機器人
      v-row.chat_icon_div.d-flex.justify-end(color="rgba(0,0,0,0)" flat class="mb-12" v-if='item.message_type == 1 && !item.sender')
        v-card(class="mr-9" max-width="350px" color="#ac52ff" dark )
          v-list-item(three-line)
            v-list-item-content
              div.upchat-message-div.d-flex.justify-end(class=" mb-4") {{item.content}}
              v-list-item-subtitle.d-flex.justify-end {{item.msg_time}}  
                //- span(class="ml-16") Seen 1:03PM  
        //- v-badge(bordered bottom color="green" dot offset-x="10" offset-y="10")
        v-avatar.chat_icon_bottom(class="" size="30" elevation="10")
          v-icon.mx-auto.chat_icon_backgroung_color(:title=`$t('UPCHAT.SENDER_B')`) {{$t('UPCHAT.ROBOT')}}

      //- 系統
      v-row.d-flex.justify-center(color="rgba(0,0,0,0)" flat class="mb-12" v-if='item.message_type == 2')
        v-card(class="mr-2" max-width="350px" color="#f1f5f8"  )
          v-list-item()
            v-list-item-content
              div.upchat-message-div(class=" mb-4") {{item.content}}
              v-list-item-subtitle {{item.msg_time}}  

      //- 聯絡人
      v-row.chat_icon_div(color="rgba(0,0,0,0)" flat class="mb-12" v-if='item.message_type == 0')
        //- v-badge(bordered bottom color="green" dot offset-x="16" offset-y="9")
        //- v-avatar(class="mt-n5 mr-2" size="30" elevation="10")
        //-   v-icon.mx-auto {{ (item.sender.name).slice(0,1) }}        
        v-card(class="" max-width="350px")
          v-list-item(three-line)
            v-list-item-content              
              div.upchat-message-div.d-flex.justify-start(class="mb-4") {{item.content}}
              div(v-if='item.attachments' )
                v-avatar(size="180" tile class="mr-2" v-for='img,index in item.attachments' :key='"img"+index' v-if='img.file_type == "image"' @click='onImage(img.data_url)')
                  img(height='180' :src="img.data_url" :href='img.data_url')
                div(v-for='file,index in item.attachments' :key='"file"+index' v-if='file.file_type == "file"')
                  v-row
                    v-col(cols='1')
                      v-icon(size='40' :scr='file.data_url' ) fa-solide fa-file
                    v-col(cols='10')
                      span {{ (decodeURI(file.data_url)).split('/')[(file.data_url.split('/')).length - 1] }}
                      br
                      v-btn.d-flex.justify-start.ml-2(:ripple="false",:href="file.data_url",width="10",height="20",depressed color='rgba(0,0,0,0)').underline {{$t('IMPORT_EXPORT.DOWNLOAD')}}
              v-list-item-subtitle.d-flex.justify-start {{item.msg_time}}
        div.chat_icon_div(v-if='item.content')
          v-btn.chat_copy_rigth(width='0' height='32' color='green' plain border-0 @click='onCopyMsg(item.content)' ) {{$t('GENERAL.COPY')}}
          
  v-card-actions
    v-row(no-gutters).mt-2.d-flex.justify-center
      v-col.pr-2(cols="6")
        v-btn(
          :ripple="false",
          @click="onCancel",
          width="100%",
          height="40",
          color="cancel",
          depressed,
          dark
        ) {{$t('GENERAL.CLOSE')}}

  v-dialog(v-model='messageDialog' width='360' content-class='statusDialog' v-if='messageDialog')
      message-dialog(@emitMessageDialog='onEmitMessageDialog' :message='message' )
  v-dialog(v-model='errorDialog' width='360' content-class='statusDialog' @keydown.esc='onEmitErrorDialog(false)' @keydown.enter='onEmitErrorDialog(true)')
      error-dialog(@emitErrorDialog='onEmitErrorDialog' :errorMessage='errorMessage')
</template>

<script>
import Vue from "vue";
import messageDialog from '@/components/Dialog/redirectDialog';
// import i18n from '/common/plugins/vue-i18n.js' 
import errorDialog from "@/components/Dialog/errorDialog";



export default Vue.extend({
  props: {    
    chatMessage: {
      type: Array,
      required: true,
    },    
  },
  components: {
    errorDialog,
    messageDialog
  },
  data() {
    return {
      errorMessage:'',
      errorDialog:false,
      messageDialog:false,
      message:"",
      valid: true,
    };
  },
  async created(){
  },
  methods: {
    onCancel() {     
      this.$emit("emitUpchatDialog");
    },
    showMessageDialog() {
    },
    showErrorDialog(message) {
      this.errorDialog = true;
      this.errorMessage = message;
    },
    onEmitErrorDialog() {
      this.errorDialog = false;
    },
    onImage(url){
      window.open(url)
    },
    onCopyMsg(event){
      const clipboardData =
      event.clipboardData ||
      window.clipboardData ||
      event.originalEvent?.clipboardData ||
      navigator.clipboard;
      clipboardData.writeText(event);
      // this.snackbar = true
    },
  },
});
</script>