<template lang='pug'>
    div
        v-combobox.no-border.height-48(v-if="!isNewContact" v-model="contact" :items="contactList" :search-input.sync="contactSearch" :menu-props="{openOnClick: true}" :no-data-text=`$t('ADD_DEAL.NO_CONTACT')` @blur='contactList = []' item-text="searchStr" item-value="id" hide-details="auto" prepend-icon='mdi-account-multiple' :label=`$t('ADD_DEAL.CONTACT')` :loading='contact_loading')
            template(v-slot:prepend-item)
                v-list-item
                    v-btn(width="120" height="32" color="blue" plain border-0 @click="isNewContact = true")
                        v-icon.mr-1(size="14") icon-add
                        span {{$t('ADD_DEAL.NEW_CONTACT')}}
            template(v-slot:item='data')
                v-avatar.ma-0.mx-2.d-none.d-lg-inline-block(color='aliceBlue' size='40')
                    v-icon(v-if="data.item.image == null") mdi-account
                    img(v-else :src='data.item.image')
                .t-black {{ data.item.full_name }} 
                .t-black.ml-2(v-if='data.item.org_name') {{ data.item.org_name }}
            template(v-slot:selection='data')
                .t-black.ellipsis(:title='data.item.org_name ? data.item.full_name + " " + data.item.org_name:data.item.full_name') {{ data.item.full_name }}
        v-row(no-gutters="no-gutters")        
            v-col(cols="6")
                v-text-field.ml-3(v-if="isNewContact" v-model="newContact.last_name" :label= `$t('ADD_CONTACT.LAST_NAME')` large="large" :rules='formRules.contactRules')
            v-col(cols="6")
                v-text-field.ml-3(v-if="isNewContact" v-model="newContact.first_name" :label=`$t('ADD_CONTACT.FIRST_NAME')` large="large" :rules='formRules.contentLenRule50')  
            v-col(cols="6")
                v-btn.w-100.justify-start.px-0(v-if="isNewContact" text color="blue" plain border-0 @click="isNewContact = false")
                    v-icon(size="20") mdi-keyboard-return 
                    span.ml-4 {{$t('ADD_DEAL.OLD_CONTACT')}}
        v-dialog(v-model='errorDialog' width='360' content-class='statusDialog' @keydown.esc='onEmitErrorDialog(false)' @keydown.enter='onEmitErrorDialog(true)')
            error-dialog(@emitErrorDialog='onEmitErrorDialog' :errorMessage='errorMessage')
</template>
<script>

import ContactDataService from "@/services/ContactDataService";
import errorDialog from "@/components/Dialog/errorDialog";
import i18n from '/common/plugins/vue-i18n.js';

export default {
    // props: {
    //     openOppties: {
    //         type: Array,
    //         require:true,
    //     }
    // },
    components: {
        errorDialog,
    },
    data() {
        return {
            contact: null,
            contactList: [],
            contactSearch: '',
            isNewContact: false,
            newContact: {
                last_name: null,
                first_name: null
            },
            contact_loading: false,
            errorDialog: false,
            errorMessage: null,
            formRules: {
                contactRules: [
                    (v) => !!v || i18n.t('RULE.RULE_R_LASTNAME'),
                    (v) => /^.{0,50}$/.test(v) || i18n.t('RULE.RULE_50')
                ],
                contentLenRule50: [(v) => /^.{0,50}$/.test(v) || i18n.t('RULE.RULE_50')],
            },
        }
    },
    methods: {
        showErrorDialog(message) {
            this.errorDialog = true;
            this.errorMessage = message;
        },
        onEmitErrorDialog() {
            this.errorDialog = false;
        },
    },
    watch: {
        contactSearch(val) {
            // check user input is en / zh / num
            let pattern = new RegExp("[\u4e00-\u9fa5_a-zA-Z0-9]");
            let isEnZhNum = false;
            pattern.test(val) ? isEnZhNum = true : isEnZhNum = false;

            // check user input data type
            let isString; 
            typeof(val) == 'string' ? isString = true : isString = false;
            let array = [];

            // search
            if(isEnZhNum && !!val && isString){
                this.contact_loading = true;
                ContactDataService.getAll(1,val,100,[])
                .then(response => {
                    response.data.list.forEach(item => {
                        item.searchStr = item.full_name 
                                        +item.full_name_en
                                        +item.full_name_en.toLowerCase()
                                        +item.full_name_en.toUpperCase()
                                        +item.full_name_zh;
                        if(item.searchStr.includes(val)){
                            if(item.organization_set.length > 0)
                            {
                                item.org_name = "[ "+item.organization_set[0].name +" ]";
                            }
                            array.push(item);
                        }
                    })
                    this.contactList = array;
                })
                .catch(response => {
                    this.showErrorDialog(response);
                })
                .finally(()=>{
                    this.contact_loading = false;
                })
            }
            else{
                this.contactList = [];
            }
        }
    },
}
</script>