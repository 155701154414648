<template lang="pug">
#SmsEditor
    loadingOverlay(v-show='loading' message='Sending SMS . . .' :zIndex='999')
    v-row.no-gutters
        v-col(cols='12')
            p.d-flex.align-center.input-has-label
                label {{$t('SMSEDITOR.RECIPIENT')}}
                v-combobox.py-1(:menu-props="{value: openMenu}" v-model='toRecipient' :items='people' item-value='id' item-text='searchStr' solo dense height='40' multiple chips :search-input.sync="search" @blur='openMenu = false' :loading='loading_recipient' :no-data-text=`$t('EMAILEDITOR.NO_DATA')` style='z-index:100;')
                    template(v-slot:selection='data' )
                        v-chip(v-bind="data.attrs"
                            :input-value="data.selected"
                            outlined
                            close=true
                            @click="data.select"
                            @click:close="removePeople(data.index)")
                            v-avatar(v-if='data.item.id != undefined' left color='aliceBlue')
                                span.mx-auto(v-if='data.item.image === null') {{ getFirstLetters(data.item.last_name + ' ' + data.item.first_name) }}
                                v-img(v-else :src='data.item.image')
                            span(v-if='data.item.id != undefined') {{ getName(data) }}
                            span(v-else) {{ data.item }}
                    template(v-slot:item='data')
                        v-list-item-avatar.mr-2(color='aliceBlue')
                            span.mx-auto(v-if='data.item.image === null') {{ data.item.last_name }}
                            img(v-else :src='data.item.image' :alt='data.item.last_name')                            
                        v-list-item-content
                            v-list-item-title(v-html='getName(data)')
        v-col(cols='12')
            p.d-flex.align-center.input-has-label
                label {{$t('SMSEDITOR.NUMBER')}}
                vue-tel-input-vuetify(v-model="mobile_phone" label=''  :placeholder=`$t('SMSEDITOR.ENTER')` autocomplete='off' :defaultCountry='getLocation()' disabledFetchingCountry=true mode='international' @validate="onPhoneCheck($event)" @input="onPhoneInput" :rules="formRule.phoneRules")
                v-btn(@click='addNumber' width='65' height='32' color='green' depressed dark)
                    span + {{$t('GENERAL.ADD')}}
        v-col.my-4(cols='12')
            v-textarea.textarea-style.p-2(v-model='body')
        v-col(cols='12')
            v-row.align-center(no-gutters)
                v-col(cols='10')
                    v-btn(@click='sendSms()' width='120' height='40' color='primary' depressed dark)
                        span {{$t('SMSEDITOR.SEND')}}
                    v-btn.ml-2(@click='initialize()' width='120' height='40' color='grey lighten-1' depressed dark)
                        span {{$t('SMSEDITOR.CLEAR')}}
                v-col(cols='2')
                    v-select(:label=`$t('SMSEDITOR.TEMPLATE')` v-model='choseSmsTemplate' :items='smsTemplateData' item-text='template_name' item-value='id' height='40' clearable solo flat filled @change='selectTemplate')
        v-dialog(v-model='errorDialog' width='360' content-class='statusDialog')
            error-dialog(@emitErrorDialog='onEmitErrorDialog' :errorMessage='errorMessage')
</template>

<script>
    import Vue from 'vue';
    import loadingOverlay from "@/components/Common/loadingOverlay";
    import TwilioService from "@/services/TwilioService";
    import SMSTemplateDataService from "@/services/SMSTemplateDataService";
    import EmailTemplateDataService from "@/services/EmailTemplateDataService";
    import ContactDataService from '@/services/ContactDataService';
    import errorDialog from '@/components/Dialog/errorDialog';
    import i18n from '/common/plugins/vue-i18n.js' 

    export default Vue.extend({
        components: {
            loadingOverlay,
            errorDialog,
        },
        props: {
            objectType: {
                type: String,
                required: true,
            },
            objectDetail: {
                type: Object,
                required: true,
            },
            contactsDetail: {
                type: Object,
                require: true,
            },
            userSms: {
                type: String
            }
        },
        data() {
            return {        
                loading: false,
                toRecipient: [],
                body: '',
                choseSmsTemplate: '',
                smsTemplateData: [],
                errorDialog: false,
                errorMessage: '',
                mobile_phone: '',
                isNumVaild: false,
                vue_tel: {
                    isValid: false,
                },
                formRule: {
                    phoneRules: [],
                },
                people: [],
                search: null,
                loading_recipient: false,
                openMenu: false,
            }
        },
        created() {            
            this.addContactToRecipient();
            SMSTemplateDataService.getAll()
            .then(response => {
                this.smsTemplateData = response.data;
            })
            .catch(response => {
                this.showErrorDialog(response);
            });
        },
        methods: {
            validateForm(){
                if(this.body != '' && this.toRecipient.length > 0)
                    return true;
                else
                    return false;
            },
            async sendSms() {
                if (this.validateForm()) {
                    this.loading = true;
                    
                    let n = 0;
                    this.toRecipient.forEach(contact => {
                        if (typeof(contact) == "string") {
                            this.toRecipient[n] = {
                                id: "",
                                mobile_phone: contact,
                            }
                        }
                        n ++;
                    });

                    let smsData = {};
                    if (this.objectType == "Opportunity") {
                        smsData = {
                            opportunity_id: this.objectDetail.id,
                            contacts: this.toRecipient,
                            body: this.body,
                        }
                    } else if (this.objectType == "Case") {
                        smsData = {
                            case_id: this.objectDetail.id,
                            contacts: this.toRecipient,
                            body: this.body,
                        }
                    } else {
                        smsData = {
                            contacts: this.toRecipient,
                            body: this.body,
                        }
                    }

                    await TwilioService.sendSMS(smsData)
                    .then(() => {
                        this.initialize();
                        // this.emailId = response.data.id;
                        this.loading = false;
                        this.$emit('emitSendSmsDialog', true);
                    }).catch(response => {
                        this.loading = false;
                        this.showErrorDialog(response);
                    });
                    this.loading = false;
                } else {
                    // this.showErrorDialog('Please enter all field before send email');
                    this.showErrorDialog(i18n.t('SMSEDITOR.ERROR'));
                }
            },
            initialize() {
                this.toRecipient = [];
                this.clearContent();
                this.addContactToRecipient();
            },
            clearContent() {
                this.body = '';
                this.choseEmailTemplate = '';            
            },
            async addContactToRecipient() {
                let hasPhone = Boolean(this.contactsDetail.mobile_phone);
                if(hasPhone){
                    var image = '';
                    await ContactDataService.getContactsDetail(this.contactsDetail.id)
                    .then(response => {
                        image = response.data.image;
                    });

                    let contactPhoneData = {
                        first_name: this.contactsDetail.first_name,
                        id: this.contactsDetail.id,
                        image: image,
                        last_name: this.contactsDetail.last_name,
                        mobile_phone: this.contactsDetail.mobile_phone,
                        searchStr: this.contactsDetail.full_name
                                    +this.contactsDetail.full_name_en
                                    +this.contactsDetail.full_name_zh
                                    +this.contactsDetail.mobile_phone
                    }
                    await this.toRecipient.push(contactPhoneData);
                }
            },
            selectTemplate(v) {
                if (v) {
                    let template_data = this.smsTemplateData.find(item => item.id == this.choseSmsTemplate)
                    EmailTemplateDataService.transformEmail(this.objectType, this.objectDetail.id, template_data.template)
                    .then(response => {
                        this.body = response.data;
                    });
                } else {
                    this.clearContent();
                }
            },
            getFirstLetters(str) {
                const firstLetters = str.split(' ').map(word => word[0]).join('');
                return firstLetters;
            },
            getName(data) {
                if (data.item.first_name != undefined || data.item.first_name != null)
                    return data.item.last_name + ' ' + data.item.first_name;
                else 
                    return data.item.last_name;
            },
            removePeople(index) {
                this.toRecipient.splice(index, 1);
            },
            showErrorDialog(message) {
                this.errorDialog = true;
                this.errorMessage = message;
            },
            onEmitErrorDialog() {
                this.errorDialog = false;
            },
            onPhoneCheck(event) {
                this.vue_tel = event;
                if (this.vue_tel.isValid == true) {
                    this.isNumVaild = true;
                }
            },
            onPhoneInput() {
                if (this.mobile_phone =="") {
                    this.isNumVaild = true;
                }
            },
            addNumber() {
                this.formRule.phoneRules.push(() => {return (this.isNumVaild || i18n.t('SMSEDITOR.ERROR_2'))});
                this.mobile_phone
                if (this.mobile_phone == "") {
                    this.formRule.phoneRules = [];
                    return;
                } else if (this.vue_tel.isValid == true) {
                    this.toRecipient.push(this.mobile_phone);
                    this.formRule.phoneRules = [];
                    this.mobile_phone = '';
                } else {
                    this.isNumVaild = false;
                    return;
                }
            },
        },
        watch: {
            async search(val){

                // check user input is en / zh / num
                let pattern = new RegExp("[\u4e00-\u9fa5_a-zA-Z0-9]");
                let isEnZhNum = false;
                pattern.test(val) ? isEnZhNum = true : isEnZhNum = false;

                // check user input data type
                let isString; 
                typeof(val) == 'string' ? isString = true : isString = false;

                if(isEnZhNum && isString && !!val){
                    
                    this.loading_recipient = true;
                    this.people = [];
                    let array = [];
                    // search
                    ContactDataService.getAll(1,val,100,[])
                    .then(response => {
                        response.data.list.forEach(item => {
                            let hasPhone = Boolean(item.mobile_phone);
                            let filter = false;
                            if(hasPhone){
                                filter = item.full_name.includes(val) 
                                        || item.full_name_en.includes(val)
                                        || (item.full_name_en.toLowerCase()).includes(val)
                                        || (item.full_name_en.toUpperCase()).includes(val)
                                        || item.full_name_zh.includes(val)
                                        || item.mobile_phone.includes(val);
                                
                                if(filter){
                                    let peopleData = {
                                        first_name: item.first_name,
                                        id: item.id,
                                        image: item.image,
                                        last_name: item.last_name,
                                        mobile_phone: item.mobile_phone,
                                        searchStr:  item.full_name 
                                                    +item.full_name_en
                                                    +item.full_name_zh
                                                    +item.full_name_en.toLowerCase()
                                                    +item.full_name_en.toUpperCase()	
                                                    +item.mobile_phone
                                    }
                                    array.push(peopleData)
                                }
                            }
                        })
                        this.people = array;
                    })
                    .catch(response => {
                        this.showErrorDialog(response);
                    })
                    .finally(()=>{
                        this.loading_recipient = false;
                        this.openMenu = true;
                    })
                    
                }
            },
        },
    });
</script>
