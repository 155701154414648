<template lang='pug'>
    v-btn.ml-3.pa-0(text height='38' color='gary' :title=`$t('GENERAL.REFRESH')` @click='refresh')
        v-icon mdi-refresh
        span {{$t('GENERAL.REFRESH')}}
</template>
<script>

export default {
    data() {
        return {
        }
    },
    methods: {
        refresh(){
            this.$emit("EmitRefresh", false);
        }
    },
}
</script>